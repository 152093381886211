@font-face {
  font-family: 'VisbyRoundCF';
  font-display: swap;
  font-weight: normal;
  src: local('VisbyRoundCF-Regular'), url(./../fonts/VisbyRoundCF-Regular.woff2) format('truetype');
}

@font-face {
  font-family: 'VisbyRoundCF';
  font-display: swap;
  font-weight: 500;
  src: local('VisbyRoundCF-DemiBold'), url(./../fonts/VisbyRoundCF-DemiBold.woff2) format('truetype');
}

@font-face {
  font-family: 'VisbyRoundCF';
  font-display: swap;
  font-weight: bold;
  src: local('VisbyRoundCF-Bold'), url(./../fonts/VisbyRoundCF-Bold.woff2) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Montserrat, sans-serif;
  background-color: #eef9fb;
  height: 100%;
  color: #0e0d0d;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
